<template>
  <v-card class="comments-message" elevation="0" width="100%">
    <v-card-text>
      <v-row align="center" justify="end">
        <v-col cols="2">
          <v-avatar color="#d4dff0">
            <img v-if="comment.anchor.userAvatarUrl" :src="comment.anchor.userAvatarUrl" alt="user-avatar-img"/>

            <span v-else class="white--text headline">
              {{ authorInitials }}
            </span>
          </v-avatar>
        </v-col>

        <v-col :cols="isEnabledEditing ? 8 : 10" class="pb-0">
          <h2>
            {{ comment.author }}<br />
            <span>
              {{ this.comment.created_at|formatDate }}
            </span>
          </h2>
        </v-col>

        <v-col v-if="isEnabledEditing" cols="1" class="pb-0 pr-0 pl-0">
          <v-btn v-if="canEditComment" icon @click="$emit('updateComment', comment)">
            <IcoEdit />
          </v-btn>
        </v-col>

        <v-col v-if="isEnabledEditing" cols="1" class="pb-0 pr-0 pl-0">
          <v-btn v-if="canEditComment" icon @click="$emit('deleteComment', comment)">
            <IcoTrash />
          </v-btn>
        </v-col>

        <v-col cols="10" class="pt-0">
          {{ comment.content }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapGetters } from "vuex"
import { parseDate } from '@/services/helpers/parseDate'

export default {
  name: 'CommentsMessage',
  components: {
    IcoEdit: () => import('@/views/dashboard/components/icons/IcoEdit'),
    IcoTrash: () => import('@/views/dashboard/components/icons/IcoTrash')
  },
  filters: {
    formatDate: (date) => {
      return date ? parseDate(moment(date).format('YYYY-MM-DD')) : ''
    }
  },
  props: {
    comment: {
      type: Object,
      required: true
    },
    canEditComment: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapGetters({
      user: 'profile'
    }),
    authorInitials () {
      return this.comment.author.split(' ').map((n) => { return n[0] }).join('')
    },
    isEnabledEditing () {
      if (typeof this.comment.anchor.userId === 'undefined') {
        return false
      }

      return parseInt(this.user.id) === parseInt(this.comment.anchor.userId)
    }
  }
}
</script>

<style lang="scss" scoped>
.comments-message {
  h2 {
    color: #000000;
    margin-bottom: 0;
    font-weight: initial;

    span {
      color: #acb1ba;
      font-size: .8rem;
      font-weight: initial;
    }
  }
}
</style>
